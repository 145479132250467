<template>
    <div id="settingIndexPage">
      <ul class="list">
        <li
          @click="onEdit(1, 1)"
          class="list-item"
        >
          <div class="left">メール連携</div>
          <div class="right">
            <div class="value">{{ $utils.storage.get('email') }}</div>
            <van-icon class="icon-arrow" name="arrow" />
          </div>
        </li>
        <li
          @click="onEdit(1, 2)"
          class="list-item"
        >
          <div class="left">パスワード変更</div>
          <div class="right">
            <van-icon class="icon-arrow" name="arrow" />
          </div>
        </li>
        <li
          @click="onEdit(2)"
          class="list-item"
        >
          <div class="left">クレジットカード変更</div>
          <div class="right">
            <van-icon class="icon-arrow" name="arrow" />
          </div>
        </li>
        <li
          @click="onEdit(3, 2)"
          class="list-item"
        >
          <div class="left">利用規約</div>
          <div class="right">
            <van-icon class="icon-arrow" name="arrow" />
          </div>
        </li>
        <li
          @click="onEdit(3, 3)"
          class="list-item"
        >
          <div class="left">サブスクリプション規約</div>
          <div class="right">
            <van-icon class="icon-arrow" name="arrow" />
          </div>
        </li>
        <li
          @click="onEdit(3, 1)"
          class="list-item"
        >
          <div class="left">プライバシーポリシー</div>
          <div class="right">
            <van-icon class="icon-arrow" name="arrow" />
          </div>
        </li>
      </ul>
      <div class="bottom">
        <button type="button" class="btn" @click="signOut">ログアウト</button>
      </div>
      <van-popup
        class="popup popup-bindPay"
        v-model="visible1"
      >
        <div class="content">调用第三方接口：填写支付账号信息</div>
      </van-popup>
    </div>
</template>

<script>
export default {
  name: 'SettingIndex',
  components: {},
  data () {
    return {
      visible1: false,
      type: ''
    };
  },
  created () {
  },
  mounted () {
    this.type = this.$route.params.type * 1;

    if (this.type === 1) {
      this.onEdit(2);
    }
  },
  watch: {},
  methods: {
    onEdit (type, query) {
      const { $router } = this;
      switch (type) {
        case 1:
          $router.push({
            name: 'SettingEdit',
            query: {
              type: query
            }
          });
          break;
        case 2:
          // this.visible1 = true;
          this.$http.get('set/changeOrBindingCard', { showLoading: true }).then(async (res) => {
            const elepay = await this.loadElepay('pk_live_90f822a7a681e4670c3d3');
            elepay.handleSource(res.data).then((result) => {
              // ① 正常処理
              if (result.type === 'cancel') {
                console.log('取消支付');
              } else if (result.type === 'success') {

                if (this.type === 1) {
                  this.$utils.toast({
                    type: 'success',
                    message: '変更に成功しました',
                    onClose: () => {
                      this.$router.go(-1);
                    }
                  });
                } else {
                  this.$router.push({
                    name: 'SuccessPay'
                  });
                }
              }
            }).catch((err) => {
              console.log(err);
            });
          });
          break;
        case 3:
          $router.push({
            name: 'Agreement',
            params: {
              type: query
            }
          });
          break;
      }
    },

    /* 退出登录状态 */
    signOut () {
      const { $utils, $router } = this;
      this.$http.get('security/logOut').then((res) => {
        $utils.storage.remove('loginToken');
        $utils.storage.remove('inputEmail');
        $utils.toast({
          message: 'ログアウト済み',
          onClose () {
            $router.push({
              name: 'Register',
              params: {
                type: 1
              }
            });
          }
        });
      });

    }
  }
};
</script>

<style scoped lang="less">
  #settingIndexPage {
    display: flex;
    flex-direction: column;
    height: 100%;

    .list {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      font-size: 26px;
      line-height: 34px;
      color: #fff;
      opacity: 0.8;

      .list-item {
        position: relative;
        min-height: 112px;
        padding: 32px 28px;

        .left {
          flex-shrink: 0;
        }

        &,
        .right {
          display: flex;
          align-items: center;
        }

        .right {
          position: relative;
          flex: 1;
          justify-content: flex-end;
          padding-right: 42px;

          .value {
            .avatar {
              width: 96px;
              height: 96px;
            }
          }

          .icon-arrow {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }

        &::after {
          position: absolute;
          bottom: 0;
          left: 28px;
          width: calc(100% - 56px);
          height: 1px;
          content: '';
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
    }

    .bottom {
      padding: 28px;

      .btn {
        width: 100%;
        height: 100px;
        font-size: 26px;
        color: rgba(255, 255, 255, 0.8);
        background-color: @primaryBlackBg;
        border-radius: 16px;
      }
    }

    .popup-bindPay {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 672px;
      height: 83%;
      max-height: 1118px;

      .content {
        width: 360px;
        font-size: 30px;
        font-weight: bold;
        line-height: 86px;
        text-align: center;
      }
    }
  }
</style>